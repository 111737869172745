<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('tpm_report.dormitory_other_report') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.fiscal_year_id"
                                  :options="fiscalYearList"
                                  id="fiscal_year_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Circular Memo No" vid="circular_memo_no">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="circular_memo_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_iabm.circular_memo_no')}}
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.circular_memo_no"
                                  :options="circularList"
                                  id="circular_memo_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-overlay :show="loading">
                        <b-row>
                          <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Type"  vid="training_type_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_type')}}
                              </template>
                              <b-form-select
                                plain
                                v-model="search.training_type_id"
                                :options="trainingTypeList"
                                id="training_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Category" vid="training_category_id">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_category_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_category')}}
                                </template>
                                <b-form-select
                                  plain
                                  v-model="search.training_category_id"
                                  :options="trainingCategoryList"
                                  id="training_category_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Title" vid="training_title_id">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_title_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_title')}}
                                </template>
                                <b-form-select
                                  plain
                                  v-model="search.training_title_id"
                                  :options="trainingTitleList"
                                  id="training_title_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                      </b-overlay>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                          &nbsp;
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </template>
        </iq-card>
        <b-row>
          <b-col md="12" v-if="showData">
            <b-overlay>
              <b-row v-if='datas.length > 0'>
                <b-col md="12">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('tpm_report.dormitory_other_report') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button class="btn_add_new" @click="printID">
                        <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
                      </b-button>
                    </template>
                    <template v-slot:body>
                      <b-overlay :show="loadingData">
                        <div id="print-area">
                          <b-row mt-5>
                            <b-col md="12" class="table-responsive">
                              <div style="border: 2px solid;margin:10px;padding:10px">
                                <b-row>
                                  <b-col>
                                    <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                                      <template v-slot:projectNameSlot>
                                        {{ }}
                                      </template>
                                      {{ $t('tpm_report.dormitory_other_report') }}
                                    </list-report-head>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <div class="text-center">
                                      <table style="width:100%;color:black;">
                                        <tr v-if="search.fiscal_year">
                                          <td align="right" style="width:45%">{{ $t('elearning_config.fiscal_year') }}</td>
                                          <td align="center" style="width:5%">:</td>
                                          <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.fiscal_year_bn : search.fiscal_year }}</td>
                                        </tr>
                                        <tr v-if="search.training_type">
                                          <td align="right" style="width:45%">{{ $t('elearning_config.training_type') }}</td>
                                          <td align="center" style="width:5%">:</td>
                                          <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_type_bn : search.training_type }}</td>
                                        </tr>
                                        <tr v-if="search.training_category">
                                          <td align="right" style="width:45%">{{ $t('elearning_config.training_category') }}</td>
                                          <td align="center" style="width:5%">:</td>
                                          <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_category_bn : search.training_category }}</td>
                                        </tr>
                                        <tr v-if="search.training_title">
                                          <td align="right" style="width:45%">{{ $t('elearning_config.training_title') }}</td>
                                          <td align="center" style="width:5%">:</td>
                                          <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_title_bn : search.training_title }}</td>
                                        </tr>
                                      </table>
                                    </div>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col md="12">
                                    <h4 class="text-bold"><b>{{ $t('tpm_report.for_trainees') }} :</b></h4>
                                    <hr class="m-0 p-0"/>
                                  </b-col>
                                </b-row>
                                <slot v-if="datas.length > 0">
                                  <b-row v-for="(info, index) in datas" :key="index">
                                    <b-col md="12" class="mt-2">
                                      <h5 class="text-bold"><b>{{ ($i18n.locale==='bn') ? info.training_title_bn : info.training_title }}</b></h5>
                                    </b-col>
                                    <b-col md="12">
                                      <table class="tg mt-3" style="width: 100%" border="1">
                                        <thead>
                                          <tr>
                                            <th style="width:7%" class="text-center">{{ $t('tpm_report.number_of_participants') }}</th>
                                            <th style="width:10%" class="text-center">{{ $t('tpm_report.total_stay_in_dorm_in_days') }}</th>
                                            <th class="text-center">{{$t('tpm_report.room_rent')}}</th>
                                            <th class="text-center">{{$t('tpm_report.vat_15_percent_room_rent')}}</th>
                                            <th class="text-center">{{$t('tpm_report.service_charge')}}</th>
                                            <th class="text-center">{{$t('tpm_report.other_fees')}}</th>
                                            <th class="text-center">{{$t('tpm_report.total')}}</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr v-for="(info1, index1) in info.training" :key="index+ index1">
                                            <td class="text-center">{{ $n(info1.total, { useGrouping: false }) }}</td>
                                            <td class="text-center">{{ $n(info1.total_days, { useGrouping: false }) }}</td>
                                            <td class="text-center">{{ $n(info1.room_rent* info1.total_days, { useGrouping: false }) }}</td>
                                            <td class="text-center">{{ $n(info1.tax_amount, { useGrouping: false }) }}</td>
                                            <td class="text-center">{{ $n(info1.service_charge, { useGrouping: false }) }}</td>
                                            <td class="text-center">{{ $n(info1.other_deduction, { useGrouping: false }) }}</td>
                                            <td class="text-center">{{ $n(info1.payment_amount, { useGrouping: false }) }}</td>
                                          </tr>
                                          <tr class="panel-body text-center mt-3" v-if="info.training.length === 0">
                                            <td class="text-center" colspan="7">{{ $t('globalTrans.noDataFound') }}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </b-col>
                                  </b-row>
                                </slot>
                                <b-row>
                                  <b-col md="12">
                                    <h4 class="text-bold"><b>{{ $t('tpm_report.for_guests') }} :</b></h4>
                                    <hr class="m-0 p-0"/>
                                  </b-col>
                                  <b-col md="12">
                                    <table class="tg mt-3" style="width: 100%" border="1">
                                      <thead>
                                        <tr>
                                          <th style="width:7%" class="text-center">{{ $t('globalTrans.sl_no') }}</th>
                                          <th style="width:10%" class="text-center">{{ $t('globalTrans.name') }}</th>
                                          <th class="text-center">{{$t('tpm_report.org_and_mobile_no')}}</th>
                                          <th style="width:10%" class="text-center">{{ $t('tpm_report.total_stay_in_dorm_in_days') }}</th>
                                          <th class="text-center">{{$t('tpm_report.room_rent')}}</th>
                                          <th class="text-center">{{$t('tpm_report.vat_15_percent_room_rent')}}</th>
                                          <th class="text-center">{{$t('tpm_report.service_charge')}}</th>
                                          <th class="text-center">{{$t('tpm_report.other_fees')}}</th>
                                          <th class="text-center">{{$t('tpm_report.total')}}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="(info, index) in bill" :key="'bill' + index">
                                          <td class="text-center">{{ $n(index + 1, { useGrouping: false }) }}</td>
                                          <td class="text-center">{{ ($i18n.locale==='bn') ? info.guest_name_bn : info.guest_name }}</td>
                                          <td class="text-center">
                                            <slot v-if="info.guest_org_id === 999">
                                              {{info.guest_org_name}}
                                            </slot>
                                            <slot v-else>
                                              {{ ($i18n.locale==='bn') ? info.org_name_bn : info.org_name }}
                                            </slot>
                                            <br/>
                                            {{($i18n.locale === 'bn' ? '০' : '0') + $n(info.mobile, { useGrouping: false })}}
                                          </td>
                                          <td class="text-center">{{ $n(info.no_of_days, { useGrouping: false }) }}</td>
                                          <td class="text-center">{{ $n(info.total_rent, { useGrouping: false }) }}</td>
                                          <td class="text-center">
                                            {{percentage(info.total_rent, info.tax)}}
                                          </td>
                                          <td class="text-center">{{ $n(info.service_charge, { useGrouping: false }) }}</td>
                                          <td class="text-center">{{ $n(info.other_deduction, { useGrouping: false }) }}</td>
                                          <td class="text-center">{{ $n(info.total_amount, { useGrouping: false }) }}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </b-col>
                                </b-row>
                                <slot v-if="venueVillgenerate.length > 0">
                                  <b-row v-for="(info, index) in venueVillgenerate" :key="index">
                                    <b-col md="12" class="mt-2">
                                      <h5 class="text-bold"><b>{{ ($i18n.locale==='bn') ? info.venue_bn : info.venue }}</b></h5>
                                    </b-col>
                                    <b-col md="12">
                                      <table class="tg mt-3" style="width: 100%" border="1">
                                        <thead>
                                          <tr>
                                            <th style="width:7%" class="text-center">{{ $t('tpm_report.number_of_participants') }}</th>
                                            <th style="width:10%" class="text-center">{{ $t('tpm_report.caf_total_use_days') }}</th>
                                            <th class="text-center">{{$t('tpm_report.rent')}}</th>
                                            <th class="text-center">{{$t('tpm_report.vat_15_percent_room_rent')}}</th>
                                            <th style="width:7%" class="text-center">{{ $t('tpm_report.electricity') }}</th>
                                            <th style="width:10%" class="text-center">{{ $t('tpm_report.gas') }}</th>
                                            <th class="text-center">{{$t('tpm_report.service_charge')}}</th>
                                            <th class="text-center">{{$t('tpm_report.other_fees')}}</th>
                                            <th class="text-center">{{$t('tpm_report.total')}}</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr v-for="(info1, index1) in info.venues" :key="index+ index1">
                                            <td class="text-center">{{ $n(info1.total_participent, { useGrouping: false }) }}</td>
                                            <td class="text-center">{{ $n(info1.total_days, { useGrouping: false }) }}</td>
                                            <td class="text-center">{{ $n(info1.rent_amount* info1.total_days, { useGrouping: false }) }}</td>
                                            <td class="text-center">{{ $n(info1.vat_tax_amount, { useGrouping: false }) }}</td>
                                            <td class="text-center">{{ $n(info1.electricity_bill_amount, { useGrouping: false }) }}</td>
                                            <td class="text-center">{{ $n(info1.gas_bill_amount, { useGrouping: false }) }}</td>
                                            <td class="text-center">{{ $n(info1.service_charge, { useGrouping: false }) }}</td>
                                            <td class="text-center">{{ $n(info1.other_deduction, { useGrouping: false }) }}</td>
                                            <td class="text-center">{{ $n(info1.payment_amount, { useGrouping: false }) }}</td>
                                          </tr>
                                          <tr class="panel-body text-center mt-3" v-if="info.venues.length === 0">
                                            <td class="text-center" colspan="7">{{ $t('globalTrans.noDataFound') }}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </b-col>
                                  </b-row>
                                </slot>
                              </div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="text-center" v-if="$i18n.locale==='bn' && datas.length > 0"><strong>{{ $t('tpm_report.idsdp') }}</strong> {{ $t('tpm_report.report_footer1') }} {{ $t('tpm_report.report_footer2') }}</b-col>
                            <b-col class="text-center" v-if="$i18n.locale==='en' && datas.length > 0">{{ $t('tpm_report.report_footer1') }} <strong>{{ $t('tpm_report.idsdp') }}</strong> {{ $t('tpm_report.report_footer2') }}</b-col>
                          </b-row>
                        </div>
                      </b-overlay>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
              <div class="panel-body text-center mt-3" v-else>
                <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
              </div>
            </b-overlay>
          </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { dormitoryOthersChargeReport, circularPublicationList, circularPublication } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHeadTrainingPr.vue'
import ExportPdf from './export_pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
  props: ['id'],
  components: {
    ListReportHead
  },
  created () {
    this.getCircularList()
    // this.searchData()
    this.search = Object.assign({}, this.search, {
      org_id: this.$store.state.dataFilters.orgId,
      fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
    })
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      search: {
        circular_memo_no: 0,
        training_type_id: 0,
        training_category_id: 0,
        training_title_id: 0,
        training_quarter_id: 0,
        fiscal_year_id: 0
      },
      trainingCategoryList: [],
      trainingTitleList: [],
      circularList: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      datas: [],
      bill: [],
      venueVillgenerate: [],
      loading: false,
      loadingData: false,
      showData: false
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  computed: {
    trainingQuarterSetupList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    i18 () {
      return this.$i18n.locale
    }
  },
  watch: {
    'search.circular_memo_no': function (newValue) {
      this.getCircularMemoNo(newValue)
    },
    'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'search.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    }
  },
  methods: {
    async getCircularList () {
      this.circularLoading = true
      const serchData = {
          org_id: this.$store.state.dataFilters.orgId
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationList + '/' + 'tpm_training_calendar' + '/' + 1, serchData)
      if (!result.success) {
      } else {
          const listObject = result.data
          const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          } else {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          }
          })
          this.circularList = tmpList
      }
      this.circularLoading = false
    },
    async getCircularMemoNo (getCircularMemoNo) {
      if (getCircularMemoNo) {
        this.loading = true
        const params = {
          circular_memo_no: getCircularMemoNo,
          table: 'tpm_training_calendar'
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
        if (!result.success) {
        } else {
          this.circularErrorMsg = false
          const trData = result.data
          this.search.training_title_id = trData.training_title_id
          this.search.training_type_id = trData.training_type_id
          this.search.training_category_id = trData.training_category_id
        }
        this.loading = false
      }
    },
    pdfExport () {
        const reportTitle = this.$t('tpm_report.dormitory_other_report')
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.datas, this, this.search)
    },
    getCategoryList (typeId) {
      const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
      if (typeId) {
        return trainingCategoryList.filter(item => item.training_type_id === typeId)
      }
      return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    getEditingData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async searchData () {
      this.loadingData = true
      this.showData = true
      this.getCustomDataSearch()
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, dormitoryOthersChargeReport, this.search)
      if (result.success) {
        this.loadingData = false
        if (result.data) {
          // this.datas = result.data
          this.datas = this.getCustomDataList(result.data)
          this.bill = this.getCustomDataBill(result.bill)
          this.venueVillgenerate = this.getVenueDataList(result.venueVillgenerate)
        }
      } else {
        this.datas = []
        this.loadingData = false
      }
    },
    getCustomDataSearch () {
      const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscalYearList => fiscalYearList.value === parseInt(this.search.fiscal_year_id))
      if (typeof fiscalYearObj !== 'undefined') {
        this.search.fiscal_year = fiscalYearObj.text_en
        this.search.fiscal_year_bn = fiscalYearObj.text_bn
      //   this.search.fiscal_year_bn = fiscalYearObj.text_bn
      } else {
        this.search.fiscal_year = ''
        this.search.fiscal_year_bn = ''
      }
      const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(item => item.value === this.search.training_type_id)
      this.search.training_type = typeof trainingTypeObj !== 'undefined' ? trainingTypeObj.text_en : ''
      this.search.training_type_bn = typeof trainingTypeObj !== 'undefined' ? trainingTypeObj.text_bn : ''

      const trainingCategoryListObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(item => item.value === this.search.training_category_id)
      this.search.training_category = typeof trainingCategoryListObj !== 'undefined' ? trainingCategoryListObj.text_en : ''
      this.search.training_category_bn = typeof trainingCategoryListObj !== 'undefined' ? trainingCategoryListObj.text_bn : ''

      const trainingTitleListObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(item => item.value === this.search.training_title_id)
      this.search.training_title = typeof trainingTitleListObj !== 'undefined' ? trainingTitleListObj.text_en : ''
      this.search.training_title_bn = typeof trainingTitleListObj !== 'undefined' ? trainingTitleListObj.text_bn : ''
    },
    percentage (partialValue, totalValue) {
      return (partialValue) / totalValue
    },
    getCustomDataBill (data) {
      const listData = data.map(item => {
        const orgData = {}
        const orgObj = this.$store.state.commonObj.organizationProfileList.find(item1 => item1.value === item.guest_org_id)
        orgData.org_name = typeof orgObj !== 'undefined' ? orgObj.text_en : ''
        orgData.org_name_bn = typeof orgObj !== 'undefined' ? orgObj.text_bn : ''
        return Object.assign({}, item, orgData)
      })
      return listData
    },
    getCustomDataList (data) {
      const listData = data.map(item => {
        const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
        const trainingTitleData = {}
        if (typeof trainingTitleObj !== 'undefined') {
          trainingTitleData.training_title = trainingTitleObj.text_en
          trainingTitleData.training_title_bn = trainingTitleObj.text_bn
        } else {
          trainingTitleData.training_title = ''
          trainingTitleData.training_title_bn = ''
        }
        return Object.assign({}, item, trainingTitleData)
      })
      return listData
    },
    getVenueDataList (data) {
      const listData = data.map(item => {
        const venueObj = this.$store.state.TrainingElearning.commonObj.venus.find(doc => doc.value === parseInt(item.venue_id))
        const venueData = {}
        if (typeof venueObj !== 'undefined') {
          venueData.venue = venueObj.text_en
          venueData.venue_bn = venueObj.text_bn
        } else {
          venueData.venue = ''
          venueData.venue_bn = ''
        }
        return Object.assign({}, item, venueData)
      })
      return listData
    },
    printID () {
      // Get HTML to print from element
      const prtHtml = document.getElementById('print-area').innerHTML

      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
         <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" media="print">
         <style>
           @media print {
              .row::after {
                  clear: both;
              }
              .row::before {
                  display: table;
                  content: " ";
              }
              .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6,
            .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-md-4, col-md-8 {
                 float: left;
            }

            .col-lg-12 {
                 width: 100%;
            }

            .col-lg-11 {
                 width: 91.66666666666666%;
            }

            .col-lg-10 {
                 width: 83.33333333333334%;
            }

            .col-lg-9 {
                  width: 75%;
            }

            .col-lg-8 {
                  width: 66.66666666666666%;
            }

             .col-lg-7 {
                  width: 58.333333333333336%;
             }

             .col-lg-6 {
                  width: 50%;
             }

             .col-lg-5 {
                  width: 41.66666666666667%;
             }

             .col-lg-4 {
                  width: 33.33333333333333%;
             }

             .col-lg-3 {
                  width: 25%;
             }

             .col-lg-2 {
                    width: 16.666666666666664%;
             }

             .col-lg-1 {
                    width: 8.333333333333332%;
              }
              .pt-4 { padding-top: 20px }
              .top-height { height: 140px }
              .mt-4 { margin-top: 20px }
              .border {
                 border: 1px solid #dee2e6 !important;
                 border-width: 1px !important;
                  border-style: solid !important;
                  border-color: #DB4237 !important;
                  font-size: 10px !important;
                  background-color: !important;
                  /*padding:0px;*/
                 -webkit-print-color-adjust:exact !important;
               }
              .border-bottom {
                  border-bottom: 1px solid #dee2e6 !important;
                  border-bottom-color: rgb(222, 226, 230);
              }
              .border-dark {
                  border-color: #343a40 !important;
              }
              .margin-top-0 {
                margin-top: 0 !important;
              }
              .text-center { text-align: center; }
              .margin-top-20 { margin-top: 40px; }
              .my-3 {
                margin:20px 0 20px;
              }
              .tg  {border-collapse:collapse;border-spacing:0;}
              .tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
                overflow:hidden;padding:10px 5px;word-break:normal;}
              .tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
                font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
              .tg .tg-0lax{text-align:left;vertical-align:top}
           }
         </style>
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    }
  }
}
</script>
